import { Button, Stack, Typography } from "@mui/material";
import { useHomeScrollContext } from "../../contexts";
import { MaxWidthWrapper } from "../Page";
import { Column, MediaStack, Row } from "../common";
import { DARK_COlORS, FONT_SIZES, FULL_HEIGHT_WITH_NAV } from "../theme";
import { TYPEFORM_URL } from "../../constants";

export default function Donate() {
  const { donateRef } = useHomeScrollContext();
  return (
    <Stack ref={donateRef} sx={{ width: "100%" }}>
      <Stack
        sx={{
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
          minHeight: FULL_HEIGHT_WITH_NAV,
          background: "linear-gradient(270deg, #24242A 40.22%, #000 82.92%)",
        }}
      >
        <MaxWidthWrapper>
          <MediaStack
            spaceBetweenRow
            sxAbove={{ alignItems: "flex-start" }}
            spacing={6}
          >
            <Column spacing={4}>
              <Column>
                <Typography variant="h1" sx={{ color: DARK_COlORS.body }}>
                  6717 Fund: By The Numbers
                </Typography>
                <Typography sx={{ color: DARK_COlORS.caption }}>
                  Carrying on Tech's tradition of excellence over 100 years beyond it's founding
                </Typography>
              </Column>
              <Breakdown />
              <ClaimPrompt />
            </Column>

            <Column sx={{ minWidth: "350px" }}>
              {/* Add donate embedded module */}
              <></>
            </Column>
          </MediaStack>
        </MaxWidthWrapper>
      </Stack>
    </Stack>
  );
}

function ClaimPrompt() {
  return (
    <Column spacing={2}>
      <Typography variant="body2" sx={{ color: DARK_COlORS.caption }}>
        If you're interested in getting in touch, fill out this form to let us know how we can help!
      </Typography>
        <Button
          variant="outlined"
          onClick={() => window.open(TYPEFORM_URL)}
          sx={{
            ":disabled": {
              color: DARK_COlORS.disabled,
              borderColor: DARK_COlORS.disabled,
            },
            width: "max-content",
            fontSize: FONT_SIZES.h3,
            color: DARK_COlORS.body,
            borderColor: DARK_COlORS.caption,
            ":hover": {
              borderColor: DARK_COlORS.body,
            },
          }}
        >
          Request Info
        </Button>
    </Column>
  );
}

function Breakdown() {
  return (
    <Column spacing={1}>
      <NumberDescription description="Dollars Raised" value={"$15,314"} />
      <NumberDescription description="Class Participation" value={"50%"} />
      <NumberDescription description="Students Supported" value={"44 and Counting!"} />
    </Column>
  );
}

function NumberDescription({
  description,
  value,
}: {
  description: string;
  value: string;
}) {
  return (
    <Row spacing={1}>
      <Typography variant="h3" sx={{ color: DARK_COlORS.body }}>
        {description}
      </Typography>
      <Typography
        variant="h3"
        sx={{ fontWeight: "normal", color: DARK_COlORS.caption }}
      >
        {value}
      </Typography>
    </Row>
  );
}
