import { ReactNode, createContext, useContext, useRef } from "react";

type RefType = React.RefObject<HTMLDivElement> | null;
const HomeScrollContext = createContext<{
  donateRef: RefType;
  aboutRef: RefType;
  foundationRef: RefType;
}>({ donateRef: null, aboutRef: null, foundationRef: null });

export const HomeScrollContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const donateRef = useRef<HTMLDivElement>(null);
  const aboutRef = useRef<HTMLDivElement>(null);
  const foundationRef = useRef<HTMLDivElement>(null);

  return (
    <HomeScrollContext.Provider
      value={{ donateRef, aboutRef, foundationRef }}
    >
      {children}
    </HomeScrollContext.Provider>
  );
};

export function useHomeScrollContext() {
  const context = useContext(HomeScrollContext);

  const options: ScrollIntoViewOptions = { behavior: "smooth", block: "start" };
  const donate = () =>
    context.donateRef?.current?.scrollIntoView(options);
  const about = () => context.aboutRef?.current?.scrollIntoView(options);
  const foundation = () =>
    context.foundationRef?.current?.scrollIntoView(options);

  const firstOnPage = about;

  const scrollTo = { firstOnPage, donate, about, foundation };

  return { ...context, scrollTo };
}
