import { Stack, Typography } from "@mui/material";
import { LOGO_SQUARE } from "../../constants";
import { Column, Image } from "../common";
import { LIGHT_SHADOW } from "../theme";
import { useHomeScrollContext } from "../../contexts";

export default function Foundation() {
  const { foundationRef } = useHomeScrollContext();
  return (
    <Stack spacing={4} ref={foundationRef}>
      <Image
        sx={{ boxShadow: LIGHT_SHADOW, p: 2 }}
        variant="circle"
        src={LOGO_SQUARE}
        size="100px"
      />
      <Description />
    </Stack>
  );
}

function Description() {
  return (
    <Column spacing={2}>
      <Typography variant="h1">About the 6717 Fund</Typography>

      <Typography color="text.secondary">
        The Tech High School Classes of '67 and '17 Scholarship (6717 Fund) was founded by the members of the Tech High School Class of 1967 and has been carried on by the Class of 2017 a half-a-century later.
      </Typography>
      <Typography color="text.secondary">
        With nearly 1000 alumni across classes, we seek to give back to the community that gave us so many opportunities.
      </Typography>
      <Typography color="text.secondary">
        The 6717 Fund aims to reward students who have shown the highest levels of ambition, resilliance, and commitment to excellence.
      </Typography>
    </Column>
  );
}
