import { Button, Stack, Typography } from "@mui/material";
import {
  INFO_EMAIL_OUTREACH,
} from "../../constants";
import { Column, Image, MediaStack, Row } from "../common";
import { LIGHT_SHADOW } from "../theme";
import { useHomeScrollContext } from "../../contexts";

const ASPECT_RATIO = 408 / 612;
const WIDTH = { mobile: 200, desktop: 400 };
export default function About() {
  const { aboutRef } = useHomeScrollContext();
  return (
    <Stack ref={aboutRef}>
      <MediaStack
        sxBelow={{ flexDirection: "column-reverse" }}
        sxAbove={{ alignItems: "flex-start" }}
        spacing={6}
      >
        {/* Add demo image */}
        <Image
          sx={{ boxShadow: LIGHT_SHADOW }}
          variant="fixed-width"
          mobileWidth="100%"
          src={""}
          width={`${WIDTH.desktop}px`}
          height={`${ASPECT_RATIO * WIDTH.desktop}px`}
        />
        <Column spacing={4}>
          <Description />
          <Button
            sx={{ width: "max-content" }}
            onClick={() => window.open(INFO_EMAIL_OUTREACH)}
          >
            Reach out
          </Button>
        </Column>
      </MediaStack>
    </Stack>
  );
}

const bullets = [
  "Resillience in the face of defeat",
  "Service over self",
  "Put in the work",
  "Humble ambition",
];

function Description() {
  return (
    <Column spacing={2}>
      <Typography variant="h1">
        A Tradition of Excellence
      </Typography>

      <Typography color="text.secondary">
        We are members of the Tech High School Classes of 1967 and 2017 helping fund future education of the Saint Cloud community.
      </Typography>

      <Column spacing={0.5}>
        <Typography variant="h4" color="text.primary">
          The 6717 Fund was formed by the class of 1967 and the scholarship has been passed to the class of 2017 half a century later. The core values of our scholarship are:
        </Typography>

        {bullets.map((b, i) => (
          <Row alignItems="flex-start" spacing={1} key={i}>
            <Typography color="text.disabled">•</Typography>
            <Typography color="text.secondary"> {b} </Typography>
          </Row>
        ))}
      </Column>
    </Column>
  );
}
